<template>
  <v-main>
    <div class="secondary">
      <v-container secondary class="py-10">
        <h4 class="text-md-h2 text-h4 primary--text font-weight-bold">
          {{ $t("how_can_we_help") }}
        </h4>
      </v-container>
    </div>
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container class="py-8" v-if="!isLoading">
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pa-5"
          v-for="item in categories"
          :key="item.name"
        >
          <router-link
            :to="'/categories/' + item.slug"
            class="text-h5 text-md-h4 text-decoration-none primary--text mr-3 d-inline-block"
          >
            {{ item.name }}
          </router-link>
          <span class="subtitle-1 grey--text d-inline-block">
            ({{ getCategoryArticles(item.slug).length }})
          </span>
          <ul class="mt-4">
            <template
              v-for="(article, index) in getCategoryArticles(item.slug)"
            >
              <li :key="article.uid" v-if="index < 5">
                <router-link
                  :to="'/articles/' + article.uid"
                  class="app-link text-decoration-none d-inline-block mb-2"
                >
                  {{ $prismic.asText(article.data.title) }}
                </router-link>
              </li>
            </template>
          </ul>
          <div>
            <router-link
              :to="'/categories/' + item.slug"
              class="primary--text text-decoration-none d-inline-block my-2"
            >
              {{ $t("see_more") }}
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: "Home",
  title: window.vm.$i18n.t("support_center"),
  props: {
    categories: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: true,
    articles: [],
  }),
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-gb";
    },
    articlesOrdered() {
      return this.articles.results.sortBy('position')
    }
  },
  methods: {
    getCategoryArticles(tag) {
      const articles =  this.articlesOrdered.filter((item) => item.tags[0] === tag);
      return articles.sortBy('position')
    },
    async loadContent() {
      this.isLoading = true;
      await this.getArticles();
      this.isLoading = false;
    },
    async getArticles() {
      const document = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "article"),
        { lang: this.locale }
      );
      this.articles = document;
    },
  },
  created() {
    this.loadContent();
  },
  beforeRouteUpdate(to, from, next) {
    this.loadContent();
    next();
  },
};
</script>
